import { useQuery } from "@apollo/client";
import DataError from "creators/components/DataError";
import DesktopNav from "creators/components/DesktopNav";
import GuardedRoute from "creators/components/GuardedRoute";
import LoadingDelayed from "creators/components/LoadingDelayed";
import MobileNav from "creators/components/MobileNav";
import SessionDetails from "creators/components/SessionDetails";
import VuiDataError from "creators/components/vui/DataError";
import VUIDesktopNav from "creators/components/vui/DesktopNav";
import VUIMobileNav from "creators/components/vui/MobileNav";
import DIMENSIONS from "creators/constants/dimensions";
import { routes as homeRoutes } from "creators/pages/Home";
import NotFound from "creators/pages/NotFound";
import VuiNotFound from "creators/pages/vui/NotFound";
import {
  ScreenSize,
  ViewportContext,
} from "creators/providers/ViewportProvider";
import { GET_VIEWER } from "creators/queries/GetViewer";
import authenticatedRoutes from "creators/routes/authenticated";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import flatten from "lodash/flatten";
import { Suspense, useContext } from "react";
import {
  Redirect,
  RedirectProps,
  Switch,
  matchPath,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import useFeature from "shared/hooks/useFeature";
import styled, { css } from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

export const Grid = styled.div<{ creatorsVUI?: boolean; $home: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  min-height: 100vh;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    grid-template-rows: 3.75rem 1fr;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    grid-template-columns: var(--v-size-nav-width) 1fr;
    grid-template-rows: none;
  }

  ${({ creatorsVUI, $home }) =>
    creatorsVUI &&
    css`
      background: ${$home
        ? `rgb(var(--vui-color-background-variant))`
        : `rgb(var(--vui-color-background))`};
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      grid-template-rows: unset;
      padding: 0;

      @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
        flex-direction: row;
      }
    `}
`;

// Redirect paths from previous Creators app to their new homes
const redirectRoutes: Array<Omit<RedirectProps, "from"> & { from: string }> = [
  {
    from: "/live-briefs",
    to: "/",
  },
  {
    from: "/my-briefs",
    to: "/active-brief",
  },
  {
    from: "/verified",
    to: {
      pathname: "/verify-email",
      state: {
        verified: true,
      },
    },
  },
  {
    from: "/not-verified",
    to: {
      pathname: "/verify-email",
      state: {
        verified: false,
      },
    },
  },
];

export const Authenticated = () => {
  const size = useContext(ViewportContext);
  const location = useLocation<{ referrerUri?: string }>();

  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);

  const creatorsVUI = useFeature("creatorsVUI");

  const homeRoute = useRouteMatch({
    path: flatten(homeRoutes.map((route) => route.path)),
    exact: true,
  });

  if (loading) {
    return <LoadingDelayed />;
  }

  if (error) {
    if (creatorsVUI) {
      return <VuiDataError error={error} />;
    }
    return <DataError error={error} />;
  }

  const emailVerified =
    data?.viewer?.emailVerificationStage === EmailVerificationStage.Verified;
  const termsAndConditionsAccepted = !!data?.viewer?.termsAndConditionsAccepted;
  const onboarded = !!data?.viewer && !onboardingIncomplete(data.viewer);

  // Redirect after login or registration
  if (["/login", "/register"].includes(location.pathname)) {
    let redirectURI = location.state?.referrerUri || "/";

    if (!termsAndConditionsAccepted) {
      redirectURI = "/terms-and-conditions";
    } else if (!emailVerified) {
      redirectURI = "/verify-email";
    } else if (!onboarded) {
      redirectURI = "/onboarding";
    }

    return <Redirect to={redirectURI} />;
  }

  const expectedRoute = !!matchPath(location.pathname, {
    path: flatten([
      ...authenticatedRoutes.map((route) => route.path),
      ...redirectRoutes.map((redirect) => redirect.from),
    ]),
    exact: true,
  });
  if (!expectedRoute) {
    if (creatorsVUI) {
      return <VuiNotFound />;
    }
    return <NotFound />;
  }

  return (
    <>
      <SessionDetails />
      <Grid $home={!!homeRoute} creatorsVUI={creatorsVUI}>
        {creatorsVUI ? (
          <>
            {size !== ScreenSize.Mobile ? <VUIDesktopNav /> : <VUIMobileNav />}
          </>
        ) : (
          <>{size === ScreenSize.Desktop ? <DesktopNav /> : <MobileNav />}</>
        )}

        <Suspense fallback={<LoadingDelayed />}>
          <Switch>
            {authenticatedRoutes.map(
              (
                {
                  component: Component,
                  exact,
                  path,
                  updatedComponent: UpdatedComponent,
                  verificationRequired,
                },
                i,
              ) => (
                <GuardedRoute
                  emailVerified={emailVerified}
                  exact={exact}
                  key={i}
                  onboarded={onboarded}
                  path={path}
                  termsAndConditionsAccepted={termsAndConditionsAccepted}
                  verificationRequired={verificationRequired}
                >
                  {creatorsVUI && UpdatedComponent ? (
                    <UpdatedComponent />
                  ) : (
                    <Component />
                  )}
                </GuardedRoute>
              ),
            )}

            {redirectRoutes.map(({ from, to }) => (
              <Redirect from={from} key={from} to={to} />
            ))}
          </Switch>
        </Suspense>
      </Grid>
    </>
  );
};

export default Authenticated;

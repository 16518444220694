import FONTS from "creators/constants/fonts";
import Video from "shared/components/Video";
import styled from "styled-components";

export const StyledVideo = styled(Video)<{ $vui?: boolean }>`
  aspect-ratio: 1;
  border-radius: 1rem;
  left: 0;
  top: 0;
  width: 100%;

  &::cue {
    background-color: ${({ $vui }) =>
      $vui
        ? "rgba(var(--vui-color-mono-9), 0.55)"
        : "rgba(var(--v-color-black-rgb), 0.55)"};
    font-family: ${FONTS.FAMILY.BODY};
  }
`;

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Time: { input: any; output: any };
};

export type AcceptBriefInviteInput = {
  id: Scalars["ID"]["input"];
};

export type Achievement = {
  __typename: "Achievement";
  level: Scalars["Int"]["output"];
  name: AchievementName;
};

export enum AchievementName {
  CompletedBriefs = "COMPLETED_BRIEFS",
  MoneyEarnings = "MONEY_EARNINGS",
  VidsyBirthday = "VIDSY_BIRTHDAY",
}

export type ApplyToBriefInput = {
  id: Scalars["ID"]["input"];
};

export enum AssetType {
  Sequence = "SEQUENCE",
  Single = "SINGLE",
}

export type Brand = {
  __typename: "Brand";
  id: Scalars["ID"]["output"];
  imageURI: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export enum BrandVisibility {
  DetailsOnly = "DETAILS_ONLY",
  Hidden = "HIDDEN",
  TeaserOnly = "TEASER_ONLY",
  Visible = "VISIBLE",
}

export type Brief = {
  __typename: "Brief";
  brand: Brand;
  brandVisibility: BrandVisibility;
  briefLead: Maybe<BriefLead>;
  creatorSelectionDate: Maybe<Scalars["Time"]["output"]>;
  deliveryLink: Scalars["String"]["output"];
  details: BriefDetails;
  id: Scalars["ID"]["output"];
  imageURI: Scalars["String"]["output"];
  jobReference: Scalars["String"]["output"];
  liveActionSurveyLink: Maybe<Scalars["String"]["output"]>;
  numberOfCreatorApplications: Scalars["Int"]["output"];
  numberOfCreators: Scalars["Int"]["output"];
  otherCreators: Maybe<Array<PublicCreator>>;
  paymentAmount: Money;
  paymentDate: Scalars["Time"]["output"];
  publishedAt: Scalars["Time"]["output"];
  recommendedVideos: Maybe<Array<Video>>;
  shortHash: Scalars["String"]["output"];
  showAEProjectFileGuide: Scalars["Boolean"]["output"];
  showDeliveryGuide: Scalars["Boolean"]["output"];
  showFindingPeople: Scalars["Boolean"]["output"];
  slug: Scalars["String"]["output"];
  state: BriefState;
  submission: Maybe<SubmissionV2>;
  submissionDate: Maybe<Scalars["Time"]["output"]>;
  summary: Scalars["String"]["output"];
  teaser: BriefTeaser;
  title: Scalars["String"]["output"];
  type: BriefType;
  videosPerCreator: Scalars["Int"]["output"];
  viewerPaymentAmount: Money;
};

export type BriefDetails = {
  __typename: "BriefDetails";
  assetsURI: Scalars["String"]["output"];
  beforeYouStart: Scalars["String"]["output"];
  creatorsDeliverables: Scalars["String"]["output"];
  filmingBestPractices: Scalars["String"]["output"];
  genericTextBlocks: Array<GenericTextBlock>;
  introduction: Scalars["String"]["output"];
  performerReleases: Scalars["Boolean"]["output"];
  releaseForms: Scalars["String"]["output"];
  releaseFormsReturnURI: Scalars["String"]["output"];
  requirements: BriefRequirements;
  videoTextBlocks: Array<VideoTextBlock>;
};

export type BriefEdge = PaginatedEdge & {
  __typename: "BriefEdge";
  cursor: Scalars["String"]["output"];
  node: Brief;
};

export type BriefLead = {
  __typename: "BriefLead";
  id: Scalars["ID"]["output"];
  imageURI: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type BriefRequirements = {
  __typename: "BriefRequirements";
  assets: Maybe<Scalars["String"]["output"]>;
  audio: Maybe<Scalars["String"]["output"]>;
  featuring: Maybe<Scalars["String"]["output"]>;
  location: Maybe<Scalars["String"]["output"]>;
  message: Maybe<Scalars["String"]["output"]>;
  propsOrItems: Maybe<Scalars["String"]["output"]>;
  software: Maybe<Scalars["String"]["output"]>;
  tone: Maybe<Scalars["String"]["output"]>;
  use: Maybe<Scalars["String"]["output"]>;
};

export enum BriefState {
  ApplicationWithdrawn = "APPLICATION_WITHDRAWN",
  Applied = "APPLIED",
  Closed = "CLOSED",
  Declined = "DECLINED",
  Invited = "INVITED",
  Open = "OPEN",
  Unknown = "UNKNOWN",
}

export type BriefTeaser = {
  __typename: "BriefTeaser";
  creatorsDeliverables: Scalars["String"]["output"];
  introduction: Scalars["String"]["output"];
  requirements: BriefRequirements;
};

export enum BriefType {
  LiveAction = "LIVE_ACTION",
  MotionGraphics = "MOTION_GRAPHICS",
}

export type BriefsResponse = PaginatedList & {
  __typename: "BriefsResponse";
  edges: Maybe<Array<BriefEdge>>;
  pageInfo: PageInfo;
};

export type ChangePasswordInput = {
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type CreateCreatorInput = {
  currency: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  location: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  portfolio: Scalars["String"]["input"];
  skills: Array<CreatorSkill>;
  termsAndConditionsAccepted: Scalars["Boolean"]["input"];
};

export type CreateCreatorV2Input = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type CreateFormLinkInput = {
  formName: FormName;
  formParameters: FormParameters;
  recipientName: Scalars["String"]["input"];
  submissionID: Scalars["ID"]["input"];
};

export type CreateUploadUri = {
  originalFilename: Scalars["String"]["input"];
};

export type CreateVideoInput = {
  originalFilename: Scalars["String"]["input"];
  position?: InputMaybe<Scalars["Int"]["input"]>;
  submissionId: Scalars["ID"]["input"];
  uploadKey: Scalars["String"]["input"];
};

export type Creator = {
  __typename: "Creator";
  avatarURI: Maybe<Scalars["String"]["output"]>;
  bio: Maybe<Scalars["String"]["output"]>;
  currency: Scalars["String"]["output"];
  disableAnalytics: Scalars["Boolean"]["output"];
  email: Scalars["String"]["output"];
  emailVerificationStage: EmailVerificationStage;
  externalID: Scalars["ID"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  location: Scalars["String"]["output"];
  payeeName: Maybe<Scalars["String"]["output"]>;
  portfolio: Scalars["String"]["output"];
  profilePrivate: Scalars["Boolean"]["output"];
  shippingAddress: Maybe<ShippingAddress>;
  skills: Maybe<Array<CreatorSkill>>;
  termsAndConditionsAccepted: Scalars["Boolean"]["output"];
};

export type CreatorPaymentDetailsInput = {
  payeeName: Scalars["String"]["input"];
};

export type CreatorProfile = {
  __typename: "CreatorProfile";
  avatarURI: Maybe<Scalars["String"]["output"]>;
  bio: Scalars["String"]["output"];
  externalID: Scalars["ID"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  location: Scalars["String"]["output"];
  portfolio: Scalars["String"]["output"];
  profilePrivate: Scalars["Boolean"]["output"];
  skills: Maybe<Array<CreatorSkill>>;
};

export enum CreatorSkill {
  LiveAction = "LIVE_ACTION",
  MotionGraphics = "MOTION_GRAPHICS",
}

export type CreatorTaxonomies = {
  __typename: "CreatorTaxonomies";
  currency: Taxonomy;
};

export type DeclineBriefInviteInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteFormInput = {
  formID: Scalars["String"]["input"];
};

export type DeleteFormOutput = {
  __typename: "DeleteFormOutput";
  success: Scalars["Boolean"]["output"];
};

export type DeleteVideoInput = {
  id: Scalars["ID"]["input"];
};

export type Deliverable = {
  __typename: "Deliverable";
  aspectRatio: Scalars["String"]["output"];
  base: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  iteration: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  maxLength: Maybe<Scalars["Int"]["output"]>;
  minLength: Maybe<Scalars["Int"]["output"]>;
  placements: Array<Maybe<Placement>>;
  requiredAssets: Scalars["Int"]["output"];
  type: AssetType;
};

export enum EmailVerificationStage {
  Pending = "PENDING",
  Unverified = "UNVERIFIED",
  Verified = "VERIFIED",
}

export type Form = {
  __typename: "Form";
  formName: FormName;
  id: Scalars["ID"]["output"];
  link: Scalars["String"]["output"];
  recipientName: Scalars["String"]["output"];
  signed: Scalars["Boolean"]["output"];
  videoBlockIDs: Maybe<Array<Scalars["Int"]["output"]>>;
};

export enum FormName {
  ReleaseAboveEighteenYearsOld = "RELEASE_ABOVE_EIGHTEEN_YEARS_OLD",
  ReleaseBelowEighteenYearsOld = "RELEASE_BELOW_EIGHTEEN_YEARS_OLD",
  ReleaseLocation = "RELEASE_LOCATION",
}

export type FormParameters = {
  vidsyBriefID: Scalars["String"]["input"];
  vidsyBriefName: Scalars["String"]["input"];
  vidsyCreatorName?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenericTextBlock = {
  __typename: "GenericTextBlock";
  title: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export enum MediaType {
  Image = "image",
  Video = "video",
}

export type Money = {
  __typename: "Money";
  amount: Scalars["Int"]["output"];
  currencyCode: Scalars["String"]["output"];
};

export type Mutation = {
  __typename: "Mutation";
  acceptBriefInvite: Submission;
  applyToBrief: Brief;
  changePassword: Scalars["Boolean"]["output"];
  createCreator: Scalars["Boolean"]["output"];
  createCreatorV2: Scalars["Boolean"]["output"];
  createCreatorVideo: Video;
  createCreatorVideoUploadURI: UploadUri;
  createFormLink: Form;
  createPaymentsCSV: PaymentsCsvResponse;
  declineBriefInvite: Submission;
  deleteCreatorVideo: Video;
  deleteForm: DeleteFormOutput;
  sendPasswordResetEmail: Scalars["Boolean"]["output"];
  submitCreatorVideos: Maybe<Array<Video>>;
  updateForm: Form;
  viewerCreateAvatarUploadURI: UploadUri;
  viewerResetEmailVerification: Scalars["Boolean"]["output"];
  viewerSendPasswordResetEmail: Scalars["Boolean"]["output"];
  viewerSendVerificationEmail: Scalars["Boolean"]["output"];
  viewerUpdateAvatar: Creator;
  viewerUpdateCreator: Creator;
  withdrawBriefApplication: SubmissionV2;
};

export type MutationAcceptBriefInviteArgs = {
  input: AcceptBriefInviteInput;
};

export type MutationApplyToBriefArgs = {
  input: ApplyToBriefInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCreateCreatorArgs = {
  input: CreateCreatorInput;
};

export type MutationCreateCreatorV2Args = {
  input: CreateCreatorV2Input;
};

export type MutationCreateCreatorVideoArgs = {
  input: CreateVideoInput;
};

export type MutationCreateCreatorVideoUploadUriArgs = {
  input: CreateUploadUri;
};

export type MutationCreateFormLinkArgs = {
  input: CreateFormLinkInput;
};

export type MutationDeclineBriefInviteArgs = {
  input: DeclineBriefInviteInput;
};

export type MutationDeleteCreatorVideoArgs = {
  input: DeleteVideoInput;
};

export type MutationDeleteFormArgs = {
  input: DeleteFormInput;
};

export type MutationSendPasswordResetEmailArgs = {
  input?: InputMaybe<SendPasswordResetEmailInput>;
};

export type MutationSubmitCreatorVideosArgs = {
  input: SubmitVideosInput;
};

export type MutationUpdateFormArgs = {
  input: UpdateFormInput;
};

export type MutationViewerCreateAvatarUploadUriArgs = {
  input: CreateUploadUri;
};

export type MutationViewerSendVerificationEmailArgs = {
  input?: InputMaybe<ViewerSendVerificationEmailInput>;
};

export type MutationViewerUpdateAvatarArgs = {
  input: UpdateAvatarInput;
};

export type MutationViewerUpdateCreatorArgs = {
  input: UpdateCreatorInput;
};

export type MutationWithdrawBriefApplicationArgs = {
  input: WithdrawBriefApplicationInput;
};

export type PageInfo = {
  __typename: "PageInfo";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
};

export type PaginatedEdge = {
  cursor: Scalars["String"]["output"];
};

export type PaginatedList = {
  pageInfo: PageInfo;
};

export type Payment = {
  __typename: "Payment";
  bonusAmount: Maybe<Money>;
  brief: Brief;
  datePaid: Maybe<Scalars["Time"]["output"]>;
  expenseAmount: Maybe<Money>;
  id: Scalars["ID"]["output"];
  state: PaymentState;
  timeAmount: Maybe<Money>;
};

export type PaymentEdge = PaginatedEdge & {
  __typename: "PaymentEdge";
  cursor: Scalars["String"]["output"];
  node: Payment;
};

export enum PaymentState {
  Paid = "PAID",
  Pending = "PENDING",
}

export type PaymentSummary = {
  __typename: "PaymentSummary";
  briefsTotal: Scalars["Int"]["output"];
  earningsTotal: Scalars["Int"]["output"];
};

export type PaymentsCsvResponse = {
  __typename: "PaymentsCSVResponse";
  CSVDownloadLink: Scalars["String"]["output"];
};

export type PaymentsResponse = PaginatedList & {
  __typename: "PaymentsResponse";
  edges: Maybe<Array<PaymentEdge>>;
  pageInfo: PageInfo;
};

export type Placement = {
  __typename: "Placement";
  id: Scalars["ID"]["output"];
  overlay: Scalars["String"]["output"];
  platform: Scalars["String"]["output"];
};

export type PublicCreator = {
  __typename: "PublicCreator";
  creatorExternalID: Maybe<Scalars["String"]["output"]>;
  imageURI: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  profilePrivate: Scalars["Boolean"]["output"];
};

export type Query = {
  __typename: "Query";
  brief: Brief;
  creatorProfile: Maybe<CreatorProfile>;
  formsForSubmission: Array<Form>;
  liveBriefsV2: BriefsResponse;
  taxonomies: CreatorTaxonomies;
  taxonomyByCurrencyCode: Taxon;
  viewer: Maybe<Creator>;
  viewerAchievements: Maybe<Array<Achievement>>;
  viewerHasPaidPayments: Scalars["Boolean"]["output"];
  viewerPaymentSummary: PaymentSummary;
  viewerPayments: PaymentsResponse;
  viewerSubmissionForBrief: Maybe<Submission>;
  viewerSubmissions: SubmissionsResponse;
};

export type QueryBriefArgs = {
  id: Scalars["ID"]["input"];
  previewHash?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCreatorProfileArgs = {
  externalID: Scalars["String"]["input"];
};

export type QueryFormsForSubmissionArgs = {
  submissionID: Scalars["ID"]["input"];
};

export type QueryLiveBriefsV2Args = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeBriefsWithoutSubmission?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  submissionStates?: InputMaybe<Array<SubmissionState>>;
  type?: InputMaybe<BriefType>;
};

export type QueryTaxonomyByCurrencyCodeArgs = {
  currencyCode: Scalars["String"]["input"];
};

export type QueryViewerPaymentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<PaymentState>;
};

export type QueryViewerSubmissionForBriefArgs = {
  briefID: Scalars["ID"]["input"];
};

export type QueryViewerSubmissionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  states: Array<SubmissionState>;
};

export type SendPasswordResetEmailInput = {
  email: Scalars["String"]["input"];
};

export type ShippingAddress = {
  __typename: "ShippingAddress";
  addressLine1: Scalars["String"]["output"];
  addressLine2: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  county: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
};

export type ShippingAddressInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Submission = {
  __typename: "Submission";
  brief: Brief;
  id: Scalars["ID"]["output"];
  state: SubmissionState;
  submittedAt: Maybe<Scalars["Time"]["output"]>;
  videos: Array<Video>;
};

export type SubmissionEdge = PaginatedEdge & {
  __typename: "SubmissionEdge";
  cursor: Scalars["String"]["output"];
  node: Submission;
};

export enum SubmissionState {
  ApplicationWithdrawn = "APPLICATION_WITHDRAWN",
  Applied = "APPLIED",
  Completed = "COMPLETED",
  Declined = "DECLINED",
  Invited = "INVITED",
  InviteAccepted = "INVITE_ACCEPTED",
  InviteDeclined = "INVITE_DECLINED",
}

export type SubmissionV2 = {
  __typename: "SubmissionV2";
  id: Scalars["ID"]["output"];
  state: SubmissionState;
  submittedAt: Maybe<Scalars["Time"]["output"]>;
  videos: Array<Video>;
};

export type SubmissionsResponse = PaginatedList & {
  __typename: "SubmissionsResponse";
  edges: Maybe<Array<SubmissionEdge>>;
  pageInfo: PageInfo;
};

export type SubmitVideosInput = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Taxon = {
  __typename: "Taxon";
  emojiCodePoints: Maybe<Array<Scalars["Int"]["output"]>>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  symbolCodePoints: Maybe<Array<Scalars["Int"]["output"]>>;
};

export type Taxonomy = {
  __typename: "Taxonomy";
  id: Scalars["ID"]["output"];
  values: Array<Maybe<Taxon>>;
};

export type UpdateAvatarInput = {
  key: Scalars["String"]["input"];
};

export type UpdateCreatorInput = {
  bio?: InputMaybe<Scalars["String"]["input"]>;
  creatorPaymentDetails?: InputMaybe<CreatorPaymentDetailsInput>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  portfolio?: InputMaybe<Scalars["String"]["input"]>;
  profilePrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingAddress?: InputMaybe<ShippingAddressInput>;
  skills?: InputMaybe<Array<CreatorSkill>>;
  termsAndConditionsAccepted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateFormInput = {
  formID: Scalars["String"]["input"];
  recipientName?: InputMaybe<Scalars["String"]["input"]>;
  submissionID: Scalars["ID"]["input"];
  videoBlockIDs?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type UploadUri = {
  __typename: "UploadURI";
  URI: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
};

export type Video = {
  __typename: "Video";
  createdAt: Scalars["Time"]["output"];
  creator: PublicCreator;
  gifURI: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  originalFilename: Scalars["String"]["output"];
  position: Maybe<Scalars["Int"]["output"]>;
  recommended: Scalars["Boolean"]["output"];
  state: VideoState;
  thumbnailURI: Scalars["String"]["output"];
  type: Maybe<MediaType>;
  videoURI: Scalars["String"]["output"];
  videoURIHDR: Maybe<Scalars["String"]["output"]>;
};

export type VideoEdge = PaginatedEdge & {
  __typename: "VideoEdge";
  cursor: Scalars["String"]["output"];
  node: Video;
};

export enum VideoState {
  Submitted = "SUBMITTED",
  Transcoded = "TRANSCODED",
  Transcoding = "TRANSCODING",
  TranscodingError = "TRANSCODING_ERROR",
}

export type VideoTextBlock = {
  __typename: "VideoTextBlock";
  adUnit: Maybe<Scalars["String"]["output"]>;
  creativeConceptID: Maybe<Scalars["String"]["output"]>;
  deliverable: Maybe<Deliverable>;
  description: Maybe<Scalars["String"]["output"]>;
  length: Maybe<Scalars["String"]["output"]>;
  lengthPrefix: Maybe<VideoTextBlockLengthPrefix>;
  platform: Maybe<Scalars["String"]["output"]>;
  ratio: Maybe<Scalars["String"]["output"]>;
  summary: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export enum VideoTextBlockLengthPrefix {
  Exact = "EXACT",
  Image = "IMAGE",
  UpTo = "UP_TO",
}

export type VideosResponse = PaginatedList & {
  __typename: "VideosResponse";
  edges: Maybe<Array<VideoEdge>>;
  pageInfo: PageInfo;
};

export type ViewerSendVerificationEmailInput = {
  pendingEmail: Scalars["String"]["input"];
};

export type WithdrawBriefApplicationInput = {
  briefID: Scalars["ID"]["input"];
};

export type GetPaymentSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaymentSummaryQuery = {
  __typename: "Query";
  viewerPaymentSummary: { __typename: "PaymentSummary"; earningsTotal: number };
};

export type CreateCreatorMutationVariables = Exact<{
  input: CreateCreatorV2Input;
}>;

export type CreateCreatorMutation = {
  __typename: "Mutation";
  createCreatorV2: boolean;
};

export type ViewerSendPasswordResetEmailMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ViewerSendPasswordResetEmailMutation = {
  __typename: "Mutation";
  result: boolean;
};

export type BrandFieldsFragment = {
  __typename: "Brand";
  id: string;
  imageURI: string;
  name: string;
};

export type BriefFieldsFragment = {
  __typename: "Brief";
  id: string;
  creatorSelectionDate: any | null;
  numberOfCreatorApplications: number;
  numberOfCreators: number;
  slug: string;
  state: BriefState;
  submissionDate: any | null;
  summary: string;
  title: string;
  type: BriefType;
  brand: { __typename: "Brand" } & BrandFieldsFragment;
};

export type BriefFieldsWithPaymentFragment = {
  __typename: "Brief";
  paymentDate: any;
  paymentAmount: { __typename: "Money" } & MoneyFieldsFragment;
  viewerPaymentAmount: { __typename: "Money" } & MoneyFieldsFragment;
} & BriefFieldsFragment;

export type BriefFieldsWithSubmissionFragment = {
  __typename: "Brief";
  submission:
    | ({ __typename: "SubmissionV2" } & SubmissionV2FieldsFragment)
    | null;
} & BriefFieldsWithPaymentFragment;

export type BriefLeadFieldsFragment = {
  __typename: "BriefLead";
  id: string;
  imageURI: string | null;
  name: string;
};

export type BriefRequirementsFieldsFragment = {
  __typename: "BriefRequirements";
  audio: string | null;
  featuring: string | null;
  location: string | null;
  propsOrItems: string | null;
  tone: string | null;
  use: string | null;
};

export type OtherCreatorsFieldsFragment = {
  __typename: "PublicCreator";
  creatorExternalID: string | null;
  imageURI: string | null;
  name: string;
  profilePrivate: boolean;
};

export type BriefDetailsFieldsFragment = {
  __typename: "BriefDetails";
  assetsURI: string;
  beforeYouStart: string;
  creatorsDeliverables: string;
  introduction: string;
  releaseForms: string;
  releaseFormsReturnURI: string;
  requirements: {
    __typename: "BriefRequirements";
  } & BriefRequirementsFieldsFragment;
  videoTextBlocks: Array<
    { __typename: "VideoTextBlock" } & VideoTextBlockFieldsFragment
  >;
};

export type ActiveBriefFieldsFragment = {
  __typename: "Brief";
  deliveryLink: string;
  jobReference: string;
  shortHash: string;
  briefLead: ({ __typename: "BriefLead" } & BriefLeadFieldsFragment) | null;
  details: { __typename: "BriefDetails" } & BriefDetailsFieldsFragment;
  otherCreators: Array<
    { __typename: "PublicCreator" } & OtherCreatorsFieldsFragment
  > | null;
} & BriefFieldsWithPaymentFragment;

export type MoneyFieldsFragment = {
  __typename: "Money";
  amount: number;
  currencyCode: string;
};

export type PageInfoFieldsFragment = {
  __typename: "PageInfo";
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export type NextPageOnlyFieldsFragment = {
  __typename: "PageInfo";
  hasNextPage: boolean;
};

export type SubmissionV2FieldsFragment = {
  __typename: "SubmissionV2";
  id: string;
  state: SubmissionState;
  submittedAt: any | null;
};

export type TaxonFieldsFragment = {
  __typename: "Taxon";
  id: string;
  emojiCodePoints: Array<number> | null;
  label: string;
  symbolCodePoints: Array<number> | null;
};

export type VideoFieldsFragment = {
  __typename: "Video";
  id: string;
  originalFilename: string;
  position: number | null;
  recommended: boolean;
  state: VideoState;
  thumbnailURI: string;
  type: MediaType | null;
  videoURI: string;
  videoURIHDR: string | null;
};

export type VideoTextBlockFieldsFragment = {
  __typename: "VideoTextBlock";
  adUnit: string | null;
  description: string | null;
  length: string | null;
  lengthPrefix: VideoTextBlockLengthPrefix | null;
  platform: string | null;
  ratio: string | null;
  summary: string | null;
  title: string | null;
};

export type AcceptBriefInviteFieldsFragment = {
  __typename: "Submission";
  id: string;
  state: SubmissionState;
};

export type AcceptBriefInviteMutationVariables = Exact<{
  input: AcceptBriefInviteInput;
}>;

export type AcceptBriefInviteMutation = {
  __typename: "Mutation";
  acceptBriefInvite: {
    __typename: "Submission";
  } & AcceptBriefInviteFieldsFragment;
};

export type ApplyToBriefFieldsFragment = {
  __typename: "Brief";
  id: string;
  state: BriefState;
  submission: {
    __typename: "SubmissionV2";
    id: string;
    state: SubmissionState;
  } | null;
};

export type ApplyToBriefMutationVariables = Exact<{
  input: ApplyToBriefInput;
}>;

export type ApplyToBriefMutation = {
  __typename: "Mutation";
  applyToBrief: { __typename: "Brief" } & ApplyToBriefFieldsFragment;
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename: "Mutation";
  result: boolean;
};

export type AvatarUploadUriFieldsFragment = {
  __typename: "UploadURI";
  key: string;
  URI: string;
};

export type CreateAvatarUploadUriMutationVariables = Exact<{
  input: CreateUploadUri;
}>;

export type CreateAvatarUploadUriMutation = {
  __typename: "Mutation";
  viewerCreateAvatarUploadURI: {
    __typename: "UploadURI";
  } & AvatarUploadUriFieldsFragment;
};

export type FormLinkFieldsFragment = { __typename: "Form"; id: string };

export type CreateFormLinkMutationVariables = Exact<{
  input: CreateFormLinkInput;
}>;

export type CreateFormLinkMutation = {
  __typename: "Mutation";
  createFormLink: { __typename: "Form" } & FormLinkFieldsFragment;
};

export type CreatePaymentsCsvDownloadLinkMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreatePaymentsCsvDownloadLinkMutation = {
  __typename: "Mutation";
  createPaymentsCSV: {
    __typename: "PaymentsCSVResponse";
    CSVDownloadLink: string;
  };
};

export type CreateVideoMutationVariables = Exact<{
  input: CreateVideoInput;
}>;

export type CreateVideoMutation = {
  __typename: "Mutation";
  createCreatorVideo: { __typename: "Video" } & VideoFieldsFragment;
};

export type CreatorVideoUploadUriFieldsFragment = {
  __typename: "UploadURI";
  key: string;
  URI: string;
};

export type CreateVideoUploadUriMutationVariables = Exact<{
  input: CreateUploadUri;
}>;

export type CreateVideoUploadUriMutation = {
  __typename: "Mutation";
  createCreatorVideoUploadURI: {
    __typename: "UploadURI";
  } & CreatorVideoUploadUriFieldsFragment;
};

export type DeclineBriefInviteFieldsFragment = {
  __typename: "Submission";
  id: string;
  state: SubmissionState;
};

export type DeclineBriefInviteMutationVariables = Exact<{
  input: DeclineBriefInviteInput;
}>;

export type DeclineBriefInviteMutation = {
  __typename: "Mutation";
  declineBriefInvite: {
    __typename: "Submission";
  } & DeclineBriefInviteFieldsFragment;
};

export type DeleteFormFieldsFragment = {
  __typename: "DeleteFormOutput";
  success: boolean;
};

export type DeleteFormMutationVariables = Exact<{
  input: DeleteFormInput;
}>;

export type DeleteFormMutation = {
  __typename: "Mutation";
  deleteForm: { __typename: "DeleteFormOutput" } & DeleteFormFieldsFragment;
};

export type DeleteVideoMutationVariables = Exact<{
  input: DeleteVideoInput;
}>;

export type DeleteVideoMutation = {
  __typename: "Mutation";
  deleteCreatorVideo: { __typename: "Video"; id: string };
};

export type SendPasswordResetEmailMutationVariables = Exact<{
  input: SendPasswordResetEmailInput;
}>;

export type SendPasswordResetEmailMutation = {
  __typename: "Mutation";
  result: boolean;
};

export type SendVerificationEmailMutationVariables = Exact<{
  input: ViewerSendVerificationEmailInput;
}>;

export type SendVerificationEmailMutation = {
  __typename: "Mutation";
  viewerSendVerificationEmail: boolean;
};

export type SubmitVideosMutationVariables = Exact<{
  input: SubmitVideosInput;
}>;

export type SubmitVideosMutation = {
  __typename: "Mutation";
  submitCreatorVideos: Array<
    { __typename: "Video" } & VideoFieldsFragment
  > | null;
};

export type UpdateAvatarFieldsFragment = {
  __typename: "Creator";
  avatarURI: string | null;
  id: string;
};

export type UpdateAvatarMutationVariables = Exact<{
  input: UpdateAvatarInput;
}>;

export type UpdateAvatarMutation = {
  __typename: "Mutation";
  viewerUpdateAvatar: { __typename: "Creator" } & UpdateAvatarFieldsFragment;
};

export type UpdateCreatorMutationVariables = Exact<{
  input: UpdateCreatorInput;
}>;

export type UpdateCreatorMutation = {
  __typename: "Mutation";
  viewerUpdateCreator: { __typename: "Creator" } & ViewerFieldsFragment;
};

export type UpdateFormFieldsFragment = {
  __typename: "Form";
  id: string;
  recipientName: string;
  videoBlockIDs: Array<number> | null;
};

export type UpdateFormMutationVariables = Exact<{
  input: UpdateFormInput;
}>;

export type UpdateFormMutation = {
  __typename: "Mutation";
  updateForm: { __typename: "Form" } & UpdateFormFieldsFragment;
};

export type UpdateSettingsMutationVariables = Exact<{
  input: UpdateCreatorInput;
}>;

export type UpdateSettingsMutation = {
  __typename: "Mutation";
  viewerUpdateCreator: { __typename: "Creator" } & SettingsFieldsFragment;
};

export type WithdrawBriefApplicationFieldsFragment = {
  __typename: "SubmissionV2";
  id: string;
  state: SubmissionState;
};

export type WithdrawBriefApplicationMutationVariables = Exact<{
  input: WithdrawBriefApplicationInput;
}>;

export type WithdrawBriefApplicationMutation = {
  __typename: "Mutation";
  withdrawBriefApplication: {
    __typename: "SubmissionV2";
  } & WithdrawBriefApplicationFieldsFragment;
};

export type ActiveBriefSubmissionFieldsFragment = {
  __typename: "Submission";
  id: string;
  state: SubmissionState;
  submittedAt: any | null;
  brief: { __typename: "Brief" } & ActiveBriefFieldsFragment;
};

export type ActiveBriefSubmissionEdgeFieldsFragment = {
  __typename: "SubmissionEdge";
  node: { __typename: "Submission" } & ActiveBriefSubmissionFieldsFragment;
};

export type ActiveBriefSubmissionsResponseFieldsFragment = {
  __typename: "SubmissionsResponse";
  edges: Array<
    { __typename: "SubmissionEdge" } & ActiveBriefSubmissionEdgeFieldsFragment
  > | null;
};

export type GetActiveBriefQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveBriefQuery = {
  __typename: "Query";
  viewerSubmissions: {
    __typename: "SubmissionsResponse";
  } & ActiveBriefSubmissionsResponseFieldsFragment;
};

export type GetActiveBriefPreviewQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  previewHash?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetActiveBriefPreviewQuery = {
  __typename: "Query";
  brief: { __typename: "Brief" } & ActiveBriefFieldsFragment;
};

export type BriefTeaserRequirementsFieldsFragment = {
  __typename: "BriefRequirements";
  assets: string | null;
  featuring: string | null;
  location: string | null;
  propsOrItems: string | null;
  tone: string | null;
};

export type BriefTeaserTeaserFieldsFragment = {
  __typename: "BriefTeaser";
  creatorsDeliverables: string;
  introduction: string;
  requirements: {
    __typename: "BriefRequirements";
  } & BriefTeaserRequirementsFieldsFragment;
};

export type BriefTeaserFieldsFragment = {
  __typename: "Brief";
  liveActionSurveyLink: string | null;
  teaser: { __typename: "BriefTeaser" } & BriefTeaserTeaserFieldsFragment;
} & BriefFieldsWithSubmissionFragment;

export type GetBriefTeaserQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  previewHash?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetBriefTeaserQuery = {
  __typename: "Query";
  brief: { __typename: "Brief" } & BriefTeaserFieldsFragment;
};

export type CreatorProfileFieldsFragment = {
  __typename: "CreatorProfile";
  avatarURI: string | null;
  bio: string;
  externalID: string;
  firstName: string;
  id: string;
  location: string;
  portfolio: string;
  skills: Array<CreatorSkill> | null;
};

export type GetCreatorProfileQueryVariables = Exact<{
  externalID: Scalars["String"]["input"];
}>;

export type GetCreatorProfileQuery = {
  __typename: "Query";
  creatorProfile:
    | ({ __typename: "CreatorProfile" } & CreatorProfileFieldsFragment)
    | null;
};

export type CurrencyTaxonFiledsFragment = {
  __typename: "Taxon";
  id: string;
  label: string;
  symbolCodePoints: Array<number> | null;
};

export type CurrencyTaxonomyFieldsFragment = {
  __typename: "Taxonomy";
  id: string;
  values: Array<({ __typename: "Taxon" } & CurrencyTaxonFiledsFragment) | null>;
};

export type CreatorTaxonomiesFieldsFragment = {
  __typename: "CreatorTaxonomies";
  currency: { __typename: "Taxonomy" } & CurrencyTaxonomyFieldsFragment;
};

export type GetCreatorTaxonomiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCreatorTaxonomiesQuery = {
  __typename: "Query";
  taxonomies: {
    __typename: "CreatorTaxonomies";
  } & CreatorTaxonomiesFieldsFragment;
};

export type LiveBriefsEdgesFieldsFragment = {
  __typename: "BriefEdge";
  cursor: string;
  node: { __typename: "Brief" } & BriefFieldsWithSubmissionFragment;
};

export type LiveBriefsFieldsFragment = {
  __typename: "BriefsResponse";
  edges: Array<
    { __typename: "BriefEdge" } & LiveBriefsEdgesFieldsFragment
  > | null;
  pageInfo: { __typename: "PageInfo" } & NextPageOnlyFieldsFragment;
};

export type GetLiveBriefsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<BriefType>;
}>;

export type GetLiveBriefsQuery = {
  __typename: "Query";
  liveBriefsV2: { __typename: "BriefsResponse" } & LiveBriefsFieldsFragment;
};

export type PreviousBriefVideoTextBlockFieldsFragment = {
  __typename: "VideoTextBlock";
  summary: string | null;
  title: string | null;
};

export type PreviousBriefDetailsFieldsFragment = {
  __typename: "BriefDetails";
  videoTextBlocks: Array<
    { __typename: "VideoTextBlock" } & PreviousBriefVideoTextBlockFieldsFragment
  >;
};

export type PreviousBriefSubmissionFieldsFragment = {
  __typename: "SubmissionV2";
  videos: Array<{ __typename: "Video" } & VideoFieldsFragment>;
} & SubmissionV2FieldsFragment;

export type RecommendedVideosFieldsFragment = {
  __typename: "Video";
  id: string;
  position: number | null;
  recommended: boolean;
  videoURI: string;
  videoURIHDR: string | null;
  creator: { __typename: "PublicCreator" } & OtherCreatorsFieldsFragment;
};

export type PreviousBriefFieldsFragment = {
  __typename: "Brief";
  details: { __typename: "BriefDetails" } & PreviousBriefDetailsFieldsFragment;
  submission:
    | ({ __typename: "SubmissionV2" } & PreviousBriefSubmissionFieldsFragment)
    | null;
  recommendedVideos: Array<
    { __typename: "Video" } & RecommendedVideosFieldsFragment
  > | null;
} & BriefFieldsFragment;

export type GetPreviousBriefQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetPreviousBriefQuery = {
  __typename: "Query";
  brief: { __typename: "Brief" } & PreviousBriefFieldsFragment;
};

export type PreviousBriefsSubmissionFieldsFragment = {
  __typename: "Submission";
  id: string;
  state: SubmissionState;
  submittedAt: any | null;
  brief: { __typename: "Brief" } & BriefFieldsWithPaymentFragment;
};

export type PreviousBriefsEdgesFieldsFragment = {
  __typename: "SubmissionEdge";
  cursor: string;
  node: { __typename: "Submission" } & PreviousBriefsSubmissionFieldsFragment;
};

export type PreviousBriefsFieldsFragment = {
  __typename: "SubmissionsResponse";
  edges: Array<
    { __typename: "SubmissionEdge" } & PreviousBriefsEdgesFieldsFragment
  > | null;
  pageInfo: { __typename: "PageInfo" } & NextPageOnlyFieldsFragment;
};

export type GetPreviousBriefsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetPreviousBriefsQuery = {
  __typename: "Query";
  viewerSubmissions: {
    __typename: "SubmissionsResponse";
  } & PreviousBriefsFieldsFragment;
};

export type SettingsFieldsFragment = {
  __typename: "Creator";
  avatarURI: string | null;
  bio: string | null;
  currency: string;
  email: string;
  emailVerificationStage: EmailVerificationStage;
  firstName: string;
  id: string;
  lastName: string;
  location: string;
  payeeName: string | null;
  portfolio: string;
  profilePrivate: boolean;
  skills: Array<CreatorSkill> | null;
};

export type GetSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSettingsQuery = {
  __typename: "Query";
  viewer: ({ __typename: "Creator" } & SettingsFieldsFragment) | null;
};

export type FormFieldsFragment = {
  __typename: "Form";
  formName: FormName;
  id: string;
  link: string;
  recipientName: string;
  signed: boolean;
  videoBlockIDs: Array<number> | null;
};

export type GetSubmissionFormsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetSubmissionFormsQuery = {
  __typename: "Query";
  formsForSubmission: Array<{ __typename: "Form" } & FormFieldsFragment>;
};

export type ViewerSubmissionForBriefFieldsFragment = {
  __typename: "Submission";
  id: string;
  state: SubmissionState;
  submittedAt: any | null;
};

export type GetSubmissionStateQueryVariables = Exact<{
  briefID: Scalars["ID"]["input"];
}>;

export type GetSubmissionStateQuery = {
  __typename: "Query";
  viewerSubmissionForBrief:
    | ({ __typename: "Submission" } & ViewerSubmissionForBriefFieldsFragment)
    | null;
};

export type ViewerSubmissionFieldsFragment = {
  __typename: "Submission";
  id: string;
  videos: Array<{ __typename: "Video" } & VideoFieldsFragment>;
};

export type GetSubmissionVideosQueryVariables = Exact<{
  briefID: Scalars["ID"]["input"];
}>;

export type GetSubmissionVideosQuery = {
  __typename: "Query";
  viewerSubmissionForBrief:
    | ({ __typename: "Submission" } & ViewerSubmissionFieldsFragment)
    | null;
};

export type TaxonomyFieldsFragment = {
  __typename: "Taxon";
  emojiCodePoints: Array<number> | null;
  label: string;
  id: string;
  symbolCodePoints: Array<number> | null;
};

export type GetTaxonomyByCurrencyCodeQueryVariables = Exact<{
  paymentCurrencyCode: Scalars["String"]["input"];
  viewerPaymentCurrencyCode: Scalars["String"]["input"];
}>;

export type GetTaxonomyByCurrencyCodeQuery = {
  __typename: "Query";
  paymentCurrencyCode: { __typename: "Taxon" } & TaxonomyFieldsFragment;
  viewerPaymentCurrencyCode: { __typename: "Taxon" } & TaxonomyFieldsFragment;
};

export type ViewerFieldsFragment = {
  __typename: "Creator";
  avatarURI: string | null;
  currency: string;
  disableAnalytics: boolean;
  email: string;
  emailVerificationStage: EmailVerificationStage;
  externalID: string;
  firstName: string;
  id: string;
  lastName: string;
  location: string;
  payeeName: string | null;
  portfolio: string;
  skills: Array<CreatorSkill> | null;
  termsAndConditionsAccepted: boolean;
};

export type GetViewerQueryVariables = Exact<{ [key: string]: never }>;

export type GetViewerQuery = {
  __typename: "Query";
  viewer: ({ __typename: "Creator" } & ViewerFieldsFragment) | null;
};

export type GetViewerAchievementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetViewerAchievementsQuery = {
  __typename: "Query";
  viewerAchievements: Array<{
    __typename: "Achievement";
    name: AchievementName;
    level: number;
  }> | null;
};

export type GetViewerPaidPaymentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetViewerPaidPaymentsQuery = {
  __typename: "Query";
  viewerHasPaidPayments: boolean;
};

export type ViewerPaymentFieldsFragment = {
  __typename: "Payment";
  id: string;
  datePaid: any | null;
  state: PaymentState;
  bonusAmount: ({ __typename: "Money" } & MoneyFieldsFragment) | null;
  brief: { __typename: "Brief" } & BriefFieldsWithPaymentFragment;
  expenseAmount: ({ __typename: "Money" } & MoneyFieldsFragment) | null;
  timeAmount: ({ __typename: "Money" } & MoneyFieldsFragment) | null;
};

export type ViewerPaymentEdgeFieldsFragment = {
  __typename: "PaymentEdge";
  cursor: string;
  node: { __typename: "Payment" } & ViewerPaymentFieldsFragment;
};

export type ViewerPaymentResponseFieldsFragment = {
  __typename: "PaymentsResponse";
  edges: Array<
    { __typename: "PaymentEdge" } & ViewerPaymentEdgeFieldsFragment
  > | null;
  pageInfo: { __typename: "PageInfo"; hasNextPage: boolean };
};

export type GetViewerPaymentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetViewerPaymentsQuery = {
  __typename: "Query";
  viewerPayments: {
    __typename: "PaymentsResponse";
  } & ViewerPaymentResponseFieldsFragment;
};

export type ViewerProfileFieldsFragment = {
  __typename: "Creator";
  avatarURI: string | null;
  bio: string | null;
  firstName: string;
  id: string;
  location: string;
  portfolio: string;
  skills: Array<CreatorSkill> | null;
};

export type GetViewerProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetViewerProfileQuery = {
  __typename: "Query";
  viewer: ({ __typename: "Creator" } & ViewerProfileFieldsFragment) | null;
};

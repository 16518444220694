import { forwardRef, FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import VisuallyHidden from "vui/components/VisuallyHidden";

interface IIcon {
  className?: string;
  component: FunctionComponent | React.FC<React.PropsWithChildren>;
  id?: string;
  label?: ReactNode;
  tabIndex?: number;
}

const Icon = forwardRef<HTMLSpanElement, IIcon>(
  ({ className, component, id, label, tabIndex }: IIcon, ref) => {
    const Component = component;
    return (
      <span className={className} id={id} ref={ref} tabIndex={tabIndex}>
        {label && <VisuallyHidden as="span">{label}</VisuallyHidden>}
        <Component />
      </span>
    );
  },
);

export const DefaultStyledIcon = styled(Icon)`
  display: inline-block;
  height: 1rem;
  width: 1rem;
`;

export default DefaultStyledIcon;

import styled from "styled-components";
import Icon from "vui/components/Icon";
import BREAKPOINTS from "vui/constants/breakpoints";

const TopBarHeader = styled.header`
  align-items: center;
  background-color: rgb(var(--vui-color-background));
  color: rgb(var(--vui-color-on-background));
  display: grid;
  gap: var(--vui-spacing-6);
  grid-template-columns: var(--vui-spacing-12) 1fr var(--vui-spacing-12);
  padding: var(--vui-spacing-2) var(--vui-spacing-1);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    padding: var(--vui-spacing-2) var(--vui-spacing-5);
  }
`;

export const TopBarActionButton = styled.button`
  align-items: center;
  background: none;
  border: 0;
  color: rgb(var(--vui-color-on-background));
  cursor: pointer;
  display: inline-flex;
  height: var(--vui-spacing-12);
  justify-content: center;
  padding: 0;
  position: relative;
  width: var(--vui-spacing-12);
  z-index: 1;

  &::before {
    background-color: rgb(var(--vui-color-surface-tertiary));
    border-radius: var(--vui-shape-corner-full);
    content: "";
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  @media (hover: hover) {
    // Only apply hover styles on devices that support hover example... tiny screens
    &:hover::before {
      opacity: 1;
    }
  }

  &:active::before {
    // So we still get the before styles on press on touch devices
    opacity: 1;
  }

  &:focus-visible {
    border-radius: 50%;
    outline: calc(var(--vui-spacing-0-5) + 1px) solid
      rgb(var(--vui-color-inverse-surface-outline));
  }
`;

export const TopBarIconBlock = styled(Icon)`
  height: var(--vui-spacing-6);
  width: var(--vui-spacing-6);
`;

const TopBarBrandBlock = styled(TopBarIconBlock)`
  color: rgb(var(--vui-color-vidsy-brand));
  margin: 0 auto;
`;

export interface IProps {
  brandLogo: React.FC;
  className?: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
}

const TopBar: React.FC<IProps> = ({
  brandLogo,
  className,
  leadingIcon: LeadingIcon,
  trailingIcon: TrailingIcon,
}) => {
  return (
    <TopBarHeader className={className}>
      <div>{LeadingIcon && LeadingIcon}</div>

      <TopBarBrandBlock component={brandLogo} />

      <div>{TrailingIcon && TrailingIcon}</div>
    </TopBarHeader>
  );
};

export default TopBar;

import Environment from "shared/types/environment";
import processEnvWarning from "shared/utils/processEnvWarning";

processEnvWarning(["AUDIENCE", "CLIENT_ID", "DOMAIN", "REALM"], "AUTH0");

const AUTH = {
  [Environment.Development]: {
    AUDIENCE: "",
    CLIENT_ID: "",
    DOMAIN: "",
    REALM: "",
  },
  [Environment.Live]: {
    AUDIENCE: "https://vidsy.co",
    CLIENT_ID: "mdbPz49XRDI4du4FOIZ3Jb3LK1BwI9C1",
    DOMAIN: "login.vidsy.co",
    REALM: "creator-live",
  },
  [Environment.Staging]: {
    AUDIENCE: process.env.AUTH0_AUDIENCE || "https://staging.vidsy.co",
    CLIENT_ID:
      process.env.AUTH0_CLIENT_ID || "5iaY0OU82YBH803medJ3eTXgJznzE1nH",
    DOMAIN: process.env.AUTH0_DOMAIN || "login.vidsy.co",
    REALM: process.env.AUTH0_REALM || "creator-staging",
  },
};

// Use staging in development for now
AUTH[Environment.Development] = AUTH[Environment.Staging];

export default AUTH;

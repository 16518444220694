import {
  IProps as IBlockContainer,
  WideBlockContainer,
} from "creators/components/Brief/BlockContainer/BlockContainer";
import DIMENSIONS from "creators/constants/dimensions";
import flattenDeep from "lodash/flattenDeep";
import { useCallback, useMemo, useState } from "react";
import { Block, BlockProperties } from "shared/BlockBuilder/global";
import useFeature from "shared/hooks/useFeature";
import styled, { css } from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";
import ImageGroup from "./components/ImageGroup";

export interface IProps {
  block: Block;
  className?: string;
  error?: string;
  handleChange?: (value: { properties: BlockProperties }) => void;
  id: string;
}

interface IContainer extends IBlockContainer {
  $vui?: boolean;
}

const Container = styled(WideBlockContainer)<IContainer>`
  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    ${({ $vui }) =>
      $vui &&
      css`
        max-width: unset;
      `};
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    ${({ $vui }) =>
      $vui &&
      css`
        max-width: 45.75rem;
      `};
  }
`;

function BriefImage({ block, handleChange, id, ...rest }: IProps) {
  const creatorsVUIFlag = useFeature("creatorsVUI");

  const value = useMemo(
    () =>
      Array.isArray(block.properties.value)
        ? flattenDeep(block.properties.value) // TODO: Replace with Array.flat once lib compiler updated
        : [block.properties.value].filter((val) => val !== undefined),
    [block.properties.value],
  );

  const [editing, setEditing] = useState<boolean>(!block.properties.value);
  const editable = !!handleChange;

  const validValues = useMemo(
    () => value.filter((uri) => uri.startsWith("http")),
    [value],
  );

  const addImage = useCallback(
    (path: string) => {
      handleChange!({
        properties: {
          value: [...validValues, path],
        },
      });
    },
    [handleChange, validValues],
  );

  const removeImage = useCallback(
    (uriIndex: number) => {
      const newValue = value.filter((_, i) => i !== uriIndex);

      handleChange!({
        properties: {
          value: newValue,
        },
      });
    },
    [handleChange, value],
  );

  const reorderImages = useCallback(
    (originalIndex: number, newIndex: number) => {
      const newValue = Array.from(value);
      const [removedValue] = newValue.splice(originalIndex, 1);
      newValue.splice(newIndex, 0, removedValue);

      handleChange!({
        properties: {
          value: newValue,
        },
      });
    },
    [handleChange, value],
  );

  return (
    <Container
      {...rest}
      $vui={creatorsVUIFlag}
      editable={editable}
      editing={editing}
      id={id}
    >
      <ImageGroup
        addImage={addImage}
        editable={editable}
        editing={editing}
        id={id}
        removeImage={removeImage}
        reorderImages={reorderImages}
        setEditing={setEditing}
        uris={validValues}
      />
    </Container>
  );
}

export default BriefImage;

import common from "./common.yml";
import dropdown from "./dropdown.yml";
import navigation from "./navigation.yml";

export const copy = {
  common,
  dropdown,
  navigation,
};

export default copy;

import styled, { css } from "styled-components";
import ProgressActivity from "vui/components/ProgressActivity";

export const ContentWrapper = styled.span<{ $loading?: boolean }>`
  display: contents;

  ${({ $loading }) =>
    $loading &&
    css`
      visibility: hidden;
    `}
`;

export const IconContainer = styled.div`
  display: inline-flex;
  height: var(--vui-spacing-6);
  line-height: var(--vui-spacing-6);
  margin-right: var(--vui-spacing-1);
  width: var(--vui-spacing-6);

  svg {
    height: var(--vui-spacing-4);
    margin: auto;
    width: var(--vui-spacing-4);
  }
`;

export const StyledProgressActivity = styled(ProgressActivity)`
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
`;

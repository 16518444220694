import styled, { keyframes } from "styled-components";
import Icon from "vui/components/Icon";
import ProgressActivityIcon from "vui/icons/progress-activity.svg";
import textualize from "vui/utils/textualize";

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(ProgressActivityIcon)`
  animation: ${rotate} 1.5s linear infinite;
`;

const ProgressActivityContainer = styled.div`
  display: inline-flex;
  color: inherit;
  height: var(--vui-spacing-4);
  width: var(--vui-spacing-4);
`;

const StyledIcon = styled(Icon)`
  height: 100%;
  width: 100%;
`;

interface IProps {
  className?: string;
}

const ProgressActivity: React.FC<IProps> = ({ className }) => {
  return (
    <ProgressActivityContainer className={className}>
      <StyledIcon
        component={LoadingIcon}
        label={textualize("common.loading")}
      />
    </ProgressActivityContainer>
  );
};

export default ProgressActivity;

import { ApolloError } from "@apollo/client";
import CrashReporterBoundary from "creators/components/CrashReporterBoundary";
import textualize from "creators/utils/textualize";
import { ErrorThrower } from "shared/components/errors/ModuleError";
import styled from "styled-components";
import { FilledButton } from "vui/components/Button";

interface IDataProps {
  error: ApolloError;
}

const ModuleError = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: var(--vui-spacing-8);

  > span {
    display: inline-block;
    margin-bottom: var(--vui-spacing-8);
  }
`;

export const DataErrorMessage = () => (
  <ModuleError>
    <span>{textualize("error.data.description")}</span>
    <FilledButton onClick={() => window.location.reload()}>
      {textualize("error.data.reloadPage")}
    </FilledButton>
  </ModuleError>
);

export const DataError = ({ error }: IDataProps) => (
  <CrashReporterBoundary fallback={DataErrorMessage}>
    <ErrorThrower error={error} />
  </CrashReporterBoundary>
);

export default DataError;

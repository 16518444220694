import NavItem from "creators/components/vui/NavItem";
import NavProfileItem from "creators/components/vui/NavProfileItem";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import Auth from "creators/services/Auth";
import Session from "creators/services/Session";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import { useCallback } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import SettingsFilledIcon from "vui/icons/filled/settings.svg";
import LogoutIcon from "vui/icons/logout.svg";
import SettingsIcon from "vui/icons/settings.svg";

export interface IProps {
  mobile?: boolean;
  viewerData?: IGetViewer;
}

const UserNav: React.FC<IProps> = ({ mobile, viewerData }) => {
  const location = useLocation();

  const profileRoute = useRouteMatch(
    `/profile/${viewerData?.viewer?.externalID}`,
  );
  const settingsRoute = useRouteMatch("/settings");

  const verifiedAndOnboarded =
    !!viewerData?.viewer &&
    viewerData.viewer.emailVerificationStage ===
      EmailVerificationStage.Verified &&
    !!viewerData.viewer.termsAndConditionsAccepted &&
    !onboardingIncomplete(viewerData.viewer);

  const signOut = useCallback(() => {
    Auth.logout();
    Session.clear();

    fireAnalyticsEvent(
      ANALYTICS.CATEGORIES.CREATOR_AUTHENTICATION,
      ANALYTICS.EVENT.LOGOUT,
      { pageName: location.pathname },
    );
  }, [location]);

  if (!viewerData?.viewer) {
    return null;
  }

  return (
    <>
      {verifiedAndOnboarded && (
        <>
          {!mobile && (
            <NavProfileItem
              active={!!profileRoute}
              creatorID={viewerData.viewer.id}
              creatorImage={viewerData.viewer.avatarURI || undefined}
              hideLabel={true}
              id={IDS.NAV.PROFILE}
              label={viewerData.viewer.firstName}
              to={`/profile/${viewerData?.viewer?.externalID}`}
            />
          )}

          <NavItem
            active={!!settingsRoute}
            hideLabel={mobile ? false : true}
            icon={SettingsIcon}
            iconFilled={SettingsFilledIcon}
            id={IDS.NAV.SETTINGS}
            label={textualize("vuiNav.settings")}
            mobile={mobile}
            to={"/settings"}
          />
        </>
      )}

      <NavItem
        active={!!settingsRoute}
        hideLabel={mobile ? false : true}
        icon={LogoutIcon}
        iconFilled={LogoutIcon}
        id={IDS.NAV.SIGN_OUT}
        label={textualize("vuiNav.signOut")}
        mobile={mobile}
        onClick={signOut}
      />
    </>
  );
};

export default UserNav;

import { ButtonHTMLAttributes, forwardRef } from "react";
import styled from "styled-components";
import {
  ContentWrapper,
  IconContainer,
  StyledProgressActivity,
} from "./styles";

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: React.FunctionComponent;
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, IProps>(
  ({ children, icon, loading, ...rest }: IProps, ref) => {
    const IconComponent = icon;
    return (
      <BaseButton $hasIcon={!!icon} ref={ref} {...rest}>
        <ContentWrapper $loading={loading}>
          {IconComponent && (
            <IconContainer>
              <IconComponent />
            </IconContainer>
          )}
          {/* span guards against Google Translate crashes */}
          <span>{children}</span>
        </ContentWrapper>

        {loading && <StyledProgressActivity />}
      </BaseButton>
    );
  },
);

type TBaseButton = {
  $hasIcon?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const BaseButton = styled.button<TBaseButton>`
  align-items: center;
  appearance: none;
  background: rgb(var(--vui-color-inverse-surface));
  border: none;
  border-radius: var(--vui-shape-corner-full);
  color: rgb(var(--vui-color-inverse-on-surface));
  cursor: pointer;
  display: inline-flex;
  font-family: var(--vui-typescale-label-large-font);
  font-size: var(--vui-typescale-label-large-size);
  font-weight: var(--vui-typescale-label-large-weight);
  height: var(--vui-spacing-10);
  justify-content: center;
  letter-spacing: var(--vui-typescale-label-large-tracking);
  line-height: var(--vui-typescale-label-large-line-height);
  padding: 0 var(--vui-spacing-6) 0
    ${({ $hasIcon }) =>
      $hasIcon ? "var(--vui-spacing-4)" : "var(--vui-spacing-6)"};
  position: relative;
  text-align: center;

  &:focus,
  &:hover {
    background-color: rgb(var(--vui-color-active-surface));
    color: rgb(var(--vui-color-active-on-surface));
  }

  &:focus {
    outline: rgb(var(--vui-color-active-surface-outline)) solid
      var(--vui-state-focus-indicator-thickness);
    outline-offset: var(--vui-state-focus-indicator-outer-offset);
  }

  &:disabled {
    background-color: rgb(var(--vui-color-disabled-surface));
    color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    cursor: default;

    &:focus,
    &:hover {
      background-color: rgb(var(--vui-color-disabled-surface));
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    }
  }

  &:active {
    background-color: rgb(var(--vui-color-active-surface));
  }
`;

// Allows `FilledButton` to be used as a link with `forwardedAs`
export const FilledButton = styled(Button)``;

export const TextButton = styled(Button)<IProps>`
  background: none;
  color: rgb(var(--vui-color-on-surface-tertiary));
  padding: 0
    ${({ icon }) => (icon ? "var(--vui-spacing-4)" : "var(--vui-spacing-3)")} 0
    var(--vui-spacing-3);

  &:active,
  &:focus,
  &:hover {
    background-color: rgb(var(--vui-color-surface-tertiary));
    color: rgb(var(--vui-color-on-surface-tertiary));
  }

  &:disabled {
    background-color: none;
    color: rgba(var(--vui-color-disabled-on-surface), 0.5);

    &:active,
    &:focus,
    &:hover {
      background-color: none;
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    }
  }
`;

export const OutlinedButton = styled(Button)`
  background: none;
  box-shadow: 0 0 0 var(--vui-spacing-0-5)
    rgb(var(--vui-color-on-surface-tertiary)) inset;
  color: rgb(var(--vui-color-on-surface-tertiary));

  &:active,
  &:focus {
    background-color: rgb(var(--vui-color-surface-tertiary));
    box-shadow: 0 0 0 var(--vui-spacing-0-5) transparent;
    color: rgb(var(--vui-color-on-surface-tertiary));
  }

  &:hover {
    background-color: rgb(var(--vui-color-surface-tertiary));
    color: rgb(var(--vui-color-on-surface-tertiary));
  }

  &:disabled {
    background-color: none;
    box-shadow: 0 0 0 var(--vui-spacing-0-5)
      rgba(var(--vui-color-disabled-on-surface-outline), 0.5);
    color: rgba(var(--vui-color-disabled-on-surface), 0.5);

    &:active,
    &:focus,
    &:hover {
      background-color: none;
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    }
  }
`;

import { useQuery } from "@apollo/client";
import Avatar from "creators/components/Avatar";
import OverlayBackdrop from "creators/components/OverlayBackdrop";
import GlobalNav from "creators/components/vui/GlobalNav";
import UserNav from "creators/components/vui/UserNav";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import NavDrawer from "vui/components/NavDrawer";
import TopBar, {
  TopBarActionButton,
  TopBarIconBlock,
} from "vui/components/TopBar";
import VisuallyHidden from "vui/components/VisuallyHidden";
import AccountIcon from "vui/icons/account-circle.svg";
import MenuOpenIcon from "vui/icons/menu-open.svg";
import MenuIcon from "vui/icons/menu.svg";
import VidsyMonogram from "vui/icons/vidsy-monogram.svg";

const AnimatedNavDrawer = animated(NavDrawer);
const AnimatedOverlay = animated(OverlayBackdrop);

const ActionContainer = styled.div`
  margin-left: var(--vui-spacing-1);
`;

const AvatarBlock = styled(Avatar)`
  height: var(--vui-spacing-6);
  width: var(--vui-spacing-6);
`;

const MobileNavContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--vui-spacing-2);
`;

// TODO: Using the existing OverlayBackdrop component until the Modal has been recreated
// in VUI. This is to ensure consistency with Modal backdrops!

const MobileNav: React.FC = () => {
  const [displayNavDrawer, setDisplayNavDrawer] = useState(false);
  const { data: viewerData } = useQuery<IGetViewer>(GET_VIEWER);

  const verifiedAndOnboarded =
    !!viewerData?.viewer &&
    viewerData.viewer.emailVerificationStage ===
      EmailVerificationStage.Verified &&
    viewerData.viewer.termsAndConditionsAccepted &&
    !onboardingIncomplete(viewerData.viewer);

  const location = useLocation();

  const openMenu = useCallback(() => {
    setDisplayNavDrawer(true);
  }, []);

  const closeMenu = useCallback(() => {
    setDisplayNavDrawer(false);
  }, []);

  const navMenuTransitions = useTransition(displayNavDrawer, {
    enter: { transform: "translateX(0%)" },
    from: {
      transform: "translateX(-100%)",
    },
    leave: { transform: "translateX(-100%)" },
  });

  const navOverlayTransitions = useTransition(displayNavDrawer, {
    config: {
      // Hide overlay faster than menu
      clamp: true,
      friction: 13,
    },
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    setDisplayNavDrawer(false);
  }, [location]);

  return (
    <>
      <TopBar
        brandLogo={VidsyMonogram}
        leadingIcon={
          <TopBarActionButton onClick={openMenu}>
            <TopBarIconBlock component={MenuIcon} />
            <VisuallyHidden>{textualize("vuiNav.open")}</VisuallyHidden>
          </TopBarActionButton>
        }
        trailingIcon={
          verifiedAndOnboarded &&
          (viewerData?.viewer ? (
            <TopBarActionButton
              as={Link}
              to={`/profile/${viewerData.viewer.externalID}`}
            >
              <AvatarBlock
                alt={viewerData.viewer.firstName}
                avatarID={viewerData.viewer.id}
                src={viewerData.viewer.avatarURI || undefined}
              />
              <VisuallyHidden>{viewerData.viewer.firstName}</VisuallyHidden>
            </TopBarActionButton>
          ) : (
            <TopBarActionButton>
              <TopBarIconBlock component={AccountIcon} />
            </TopBarActionButton>
          ))
        }
      />

      {displayNavDrawer && <></>}

      {navOverlayTransitions(
        (props, item, { key }) =>
          item && (
            <AnimatedOverlay
              key={key}
              onClick={closeMenu}
              style={props}
              vui={true}
            />
          ),
      )}
      {navMenuTransitions(
        (props, item, { key }) =>
          item && (
            <AnimatedNavDrawer key={key} style={props}>
              <ActionContainer>
                <TopBarActionButton onClick={closeMenu}>
                  <TopBarIconBlock component={MenuOpenIcon} />
                  <VisuallyHidden>
                    {textualize("vuiCommon.close")}
                  </VisuallyHidden>
                </TopBarActionButton>
              </ActionContainer>

              <MobileNavContent>
                <GlobalNav mobile={true} />
                <UserNav mobile={true} viewerData={viewerData} />
              </MobileNavContent>
            </AnimatedNavDrawer>
          ),
      )}
    </>
  );
};

export default MobileNav;

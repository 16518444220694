import useVideoControls from "creators/hooks/useVideoControls";
import { useCallback, useEffect, useRef } from "react";
import { StyledVideo } from "./styles";

export interface IProps {
  captions?: string;
  className?: string;
  id?: string;
  onVideoInteraction?: () => void;
  src: string;
  vui?: boolean; // Temporary fix until we have a VUI VideoPlayer
}

export const OnboardingVideoPlayer: React.FC<
  React.PropsWithChildren<IProps>
> = ({ captions, className, id, onVideoInteraction, src, vui }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const trackRef = useRef<HTMLTrackElement>(null);

  const {
    muted,
    onHideCaptions,
    onMute,
    onPause,
    onPlay,
    onShowCaptions,
    onUnmute,
    playing,
    showCaptions,
  } = useVideoControls();

  // `play` and `volumechange` events are fired for the initial state of the video
  // These do not count as interactions, and should be ignored
  const ignorePlayEvent = useRef(playing);
  const ignoreVolumeChangeEvent = useRef(true);

  // Keep our UI in sync with any external media changes
  useEffect(() => {
    const player = videoRef.current!;

    const handlePlay = () => {
      onPlay();

      if (!ignorePlayEvent.current) {
        onVideoInteraction?.();
      } else {
        ignorePlayEvent.current = false;
      }
    };

    const handlePause = () => {
      // Only register pause if it's not caused by the video ending
      if (player.currentTime !== player.duration) {
        onPause();

        onVideoInteraction?.();
      }
    };

    const handleVolumeChange = () => {
      if (player.muted) {
        onMute();
      } else {
        onUnmute();
      }

      if (!ignoreVolumeChangeEvent.current) {
        onVideoInteraction?.();
      } else {
        ignoreVolumeChangeEvent.current = false;
      }
    };

    player.addEventListener("play", handlePlay);
    player.addEventListener("pause", handlePause);
    player.addEventListener("volumechange", handleVolumeChange);

    return () => {
      player.removeEventListener("play", handlePlay);
      player.removeEventListener("pause", handlePause);
      player.removeEventListener("volumechange", handleVolumeChange);
    };
  }, [onMute, onPause, onPlay, onUnmute, onVideoInteraction, videoRef]);

  useEffect(() => {
    const player = videoRef.current!;

    if (playing) {
      // Trying to play before the video has loaded will throw
      if (player.readyState === 4) {
        // Video has loaded
        player.play();
      } else {
        // Start playing once the video has loaded
        player.addEventListener("loadeddata", onPlay);

        return () => {
          player.removeEventListener("loadeddata", onPlay);
        };
      }
    } else {
      player.pause();
    }

    return;
  }, [onPlay, playing]);

  const handleTrackChange = useCallback(() => {
    if (trackRef.current?.track?.mode === "disabled") {
      onHideCaptions();
    } else {
      onShowCaptions();
    }
    onVideoInteraction?.();
  }, [onHideCaptions, onShowCaptions, onVideoInteraction]);

  const handleLoadedData = useCallback(() => {
    if (videoRef.current) {
      videoRef.current?.textTracks?.addEventListener?.(
        "change",
        handleTrackChange,
      );
    }
  }, [handleTrackChange]);

  return (
    <StyledVideo
      $vui={vui} // TODO: Clean up when VUI VideoPlayer is implmeneted
      autoPlay={playing}
      className={className}
      controls={true}
      crossOrigin="use-credentials"
      id={id}
      muted={muted}
      onLoadedData={handleLoadedData}
      playsInline={true}
      ref={videoRef}
      source={src}
    >
      {captions && (
        <track
          default={showCaptions}
          kind="captions"
          ref={trackRef}
          src={captions}
          srcLang="en"
        />
      )}
    </StyledVideo>
  );
};

export default OnboardingVideoPlayer;

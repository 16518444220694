import AUTH from "creators/constants/auth";
import AuthService from "shared/services/Auth/Auth";
import getEnvironment from "shared/utils/getEnvironment";

const { AUDIENCE, CLIENT_ID, DOMAIN, REALM } = AUTH[getEnvironment()];

export const Auth = new AuthService({
  audience: AUDIENCE,
  clientID: CLIENT_ID,
  domain: DOMAIN,
  realm: REALM,
});

export default Auth;

// TODO: Replace with VUI Modal/Dialog
import ModalWrapper from "creators/components/Modal/ModalWrapper";
import textualize from "creators/utils/textualize";
import { Link } from "react-router-dom";
import { FilledButton } from "vui/components/Button";
import { ButtonWrapper, Heading, Message, StyledStandardModal } from "./styles";

const SessionExpiredModal = () => {
  return (
    <ModalWrapper>
      <StyledStandardModal>
        <Heading>{textualize("modals.sessionExpired.heading")}</Heading>

        <Message>{textualize("modals.sessionExpired.message")}</Message>

        <ButtonWrapper>
          <FilledButton forwardedAs={Link} to="/login">
            {textualize("modals.sessionExpired.login")}
          </FilledButton>
        </ButtonWrapper>
      </StyledStandardModal>
    </ModalWrapper>
  );
};

export default SessionExpiredModal;

import styled, { css } from "styled-components";
import Icon from "vui/components/Icon";

export const NavRailContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: fixed;
  overflow: auto;
  z-index: 100;
`;

export const NavRailContent = styled.nav`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: var(--vui-spacing-20);
`;

export const NavRailContentItemBadge = styled.span`
  display: block;
  height: var(--vui-spacing-6);
  left: 0;
  margin: 0 auto var(--vui-spacing-1);
  position: absolute;
  right: 0;
  top: 0;
  width: var(--vui-spacing-6);

  &::after {
    background: rgb(var(--vui-color-sentiment-negative));
    border-radius: var(--vui-shape-corner-full);
    content: "";
    height: 0.375rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.375rem;
  }
`;

export const NavRailContentItemLabel = styled.span`
  color: rgb(var(--vui-color-on-surface-secondary));
  font-family: var(--vui-typescale-label-medium-font);
  font-size: var(--vui-typescale-label-medium-size);
  font-weight: var(--vui-typescale-label-medium-weight);
  line-height: var(--vui-typescale-label-medium-line-height);
`;

const NavRailContentItem = css`
  align-items: center;
  color: rgb(var(--vui-color-on-surface-secondary));
  display: flex;
  height: var(--vui-spacing-8);
  justify-content: center;
  margin: 0 auto var(--vui-spacing-1);
  position: relative;
  width: var(--vui-spacing-14);

  &::before {
    background: transparent;
    border-radius: var(--vui-shape-corner-full);
    content: "";
    height: var(--vui-spacing-8);
    position: absolute;
    opacity: 0;
    width: var(--vui-spacing-14);
    z-index: -1;
  }
`;

export const NavRailContentItemIcon = styled(Icon)`
  ${NavRailContentItem}

  & > svg {
    height: var(--vui-spacing-6);
    width: var(--vui-spacing-6);
  }
`;

export const NavRailContentItemImage = styled.div`
  ${NavRailContentItem}

  & > div,
  & > img {
    height: var(--vui-spacing-6);
    width: var(--vui-spacing-6);
  }
`;

export const NavRailContentItemOuter = styled.div<{ active?: boolean }>`
  display: block;
  margin: 0 auto;
  position: relative;
  text-align: center;
`;

export const NavRailFooter = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2.625rem;
`;

export const NavRailHeader = styled.div`
  align-items: center;
  color: rgb(var(--vui-color-vidsy-brand));
  display: flex;
  height: 5rem;
  justify-content: center;
  margin-bottom: var(--vui-spacing-10);
  margin-top: var(--vui-spacing-11);

  & svg {
    height: var(--vui-spacing-10);
    width: var(--vui-spacing-10);
  }
`;
